.col-img{
    width: 100%;
    height:100vh;
}
.col-img img{
    width: 100%;
    height: 70%;
    object-fit: cover;
    object-position: center;
}
